





















































import {Vue, Component, Emit, Watch} from "vue-property-decorator";
import RegisterContType from "@/views/Login/Register/components/Cont/indexType";
import {getSmsCode} from "@/views/Login/Register/Server";
import {Toast} from "vant";

@Component({name: "RegisterCont"})
export default class RegisterCont extends Vue implements RegisterContType{
    AccountIcon = require("@/views/Login/Img/accunt.png");
    PassWorldIcon = require("@/views/Login/Img/pass.png")
    Account = ""
    PassWorld = ""
    Code = "获取验证码"
    ClickCodeBool = false

    handleClickCode(){
        if (this.ClickCodeBool)return;
        if ( (this.Account+'').length < 10 ){
            Toast("请输入账号")
            return
        }
        getSmsCode(this.Account).then(res=>{
            this.ClickCodeBool = true
            let Num = 60;
            let time = setInterval(()=>{
                if (Num) {
                    this.Code = (Num + '')
                }else{
                    this.Code = "重新获取"
                    this.ClickCodeBool = false
                    clearInterval( time )
                }
                Num --;
            },1000)
        })
    }

    @Emit("pullData")
    pullData<T=string>(data:{ account:T,password:T }){ return data }

    @Watch("Account")
    changeAccount(newVal:string){
        this.pullData({ account:newVal,password:this.PassWorld })
    }
    @Watch("PassWorld")
    changePassWorld(newVal:string){
        this.pullData({ account:this.Account,password:newVal })
    }
}
