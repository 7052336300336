


















import {Vue, Component} from "vue-property-decorator";
import RegisterType from "@/views/Login/Register/Register";
import ZoomPage from "@/impView/PageSafety";
import RegisterHead from "@/views/Login/Register/components/Head/index.vue";
import RegisterCont from "@/views/Login/Register/components/Cont/index.vue";
import RegisterFooter from "@/views/Login/Register/components/Footer/index.vue";
import {codeLogin} from "@/views/Login/Register/Server";
import {Toast} from "vant";
import {IndexStore} from "@/store/modules/Index";

@Component({name: "Register",components:{ RegisterHead,RegisterCont,RegisterFooter }})
export default class Register extends ZoomPage implements RegisterType{
    Account = ""
    PassWorld = ""
    loadingShow = true

    mounted(){
        this.loadingShow = false
    }

    handleGetData<T=string>(e:{ account:T,password:T }){
        let { account,password } = e;
        this.Account = account as unknown as string
        this.PassWorld = password as unknown as string
    }

    handleClickBtn(): void {
        codeLogin({ phone:this.Account,smsCode:this.PassWorld }).then(res=>{
            if( !( ( res.name || res.nickName ) && res.phone && res.headImg && res.school && res.schoolName ) ){
                Toast({
                    message:"跳转补全信息",
                    icon:"none",
                    className:"ToastClassName"
                })
                let time = setTimeout(()=>{
                    this.$router.replace({
                        name:"vTMessage"
                    })
                    clearTimeout( time )
                },1000)
            }else{
                if( !res.phone ){ // 重新注册绑定
                    this.$router.replace({
                        path:"/userSetPass",
                    })
                    return;
                }
                if ( !(res.schoolName && res.school ) ){ // 跳转到 绑定学校
                    this.$router.replace({
                        path:"/school",
                    })
                    return;
                }
                IndexStore.SetLoading( true )
                this.$router.replace({
                    name:"home"
                })
            }
        })
    }
}
