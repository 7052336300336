































import {Vue, Component} from "vue-property-decorator";
import RegisterFooterType from "@/views/Login/Register/components/Footer/indexType";
import {Toast} from "vant";

@Component({name: "RegisterFooter"})
export default class RegisterFooter extends Vue implements RegisterFooterType{
    RadioIcon = require("@/views/Login/Img/defaultRadio.png")

    handleToQuery(){
        Toast("开发中~")
    }
}
