










import {Vue, Component} from "vue-property-decorator";
import RegisterHeadType from "./indexType";

@Component({name: "RegisterHead"})
export default class RegisterHead extends Vue implements RegisterHeadType{
    BackIcon = require("@/views/Login/Img/Register.png")
    IcoIcon = require("@/assets/ico/Ico.png")
}
